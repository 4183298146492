// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"; // Named import 사용
import { getDatabase } from "firebase/database"; // Realtime Database 사용
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCFuvkzFJ_at7viSf4Azuyw2ihf96tOYSc",
  authDomain: "matembox-d190b.firebaseapp.com",
  databaseURL: "https://matembox-d190b.firebaseio.com",
  projectId: "matembox-d190b",
  storageBucket: "matembox-d190b.appspot.com",
  messagingSenderId: "47172735010",
  appId: "1:47172735010:web:4814917c4446eb92697a7b",
  measurementId: "G-R8GCY1MNEV"
};
 
// Firebase 초기화
const app = initializeApp(firebaseConfig); // initializeApp 호출
const database = getDatabase(app); // getDatabase로 Database 초기화

export { database };