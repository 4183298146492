// src/App.js
import React, { useEffect, useState } from 'react';
import { database } from './firebase'; // firebase에서 database 가져오기
import { ref, onValue, child } from "firebase/database"; // Firebase 9 방식으로 impor
import './styles.css'; // Import your CSS file
import MainTitle from './assets/maintitle.png';
import TopLogo from './assets/top_logo.png';
import MaleIcon from './assets/male_icon.png';
import FemaleIcon from './assets/female_icon.png';
import Male from './assets/male.png';
import FeMale from './assets/female.png';
import FemaleTitle from './assets/female_title.png';
import MaleTitle from './assets/male_title.png';
import Refresh from './assets/refresh_icon.png';
import Home from './assets/home.png';
function MainView({onClickGender}){

  return  (<div className="container" >  
    <div className='toplococontainer'>
      <img src={TopLogo}   className='toplogo' />
      </div>
      <div className='maintitlecontainer'>
    <img src={MainTitle}    className='maintitle' />
    </div>

    <div className="horizontal-group">
      <div className='rounded-shadow-border' onClick={()=> onClickGender('male') }>  <img src={MaleIcon}   className='gendericon' /> 
      <img src={Male}  className='gendertxt'  /> 
       </div>
      <div className='rounded-shadow-border'   onClick={()=> onClickGender('female') } >  <img src={FemaleIcon}   className='gendericon' />  
      <img src={FeMale}  className='gendertxt'  /> 
      </div>  
      </div>
    </div>)
}

 

function App() { 
  const [ listFemale, setListFemale] = useState(null);
  const [ listMale, setListMale] = useState(null);
  const [ usingFemale, setUsingFemale] = useState(0);
  const [ usingMale, setUsingMale] = useState(0);
  const [ leftFemale, setLeftFemale] = useState(0);
  const [ leftMale, setLeftMale] = useState(0);
  const [state, setState] = useState(0);

   
 


  useEffect(() => {
    const dataRef = ref(database, 'TSA_RFID');
    
    onValue(dataRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        
        // 객체 형태를 배열로 변환하여 사용
        const messageArray = Object.keys(data).map(key => ({
          id: key, // 각 객체의 키를 id로 설정
          ...data[key] // 나머지 데이터들 스프레드
        }));
         const listF =  [];
         const listM =  [];
        var  leftF = 0;
        var  usingF = 0;
        var  leftM = 0;
        var  usingM = 0;
        messageArray.forEach(item=>{ 
         
          // if(item.RFDIV !== "1" && item.NO === "00013"){
          //             console.log("id: "+ item.RFIDV);
          // }
          if( item.INOUTDIV !== 'u'){
            //item.INOUTDIV !== 'x'&&
             
              if(item.RFDIV !== "1"){
                if(parseInt(item.NO) <=24){ 
                  console.log("REGNAME:"+item.REGNAME+","+item.NO+","+item.RFIDV+","+item.E_ESTATE);
                if(item.STATE === "1"  ){
                  leftF = leftF+1;
                }else{
                  usingF = usingF+1;
                }
              
                listF.push(item);
              }
              }else{
                if(parseInt(item.NO) <=32){ 
                if(item.STATE === "1"  ){
                  leftM = leftM+1;
                }else{
                  usingM = usingM+1;
                }
                listM.push(item);
              }
              }
          }  
        }
      )
        listF.sort((a,b) => a.NO.localeCompare(b.NO) )
        listM.sort((a,b) =>  a.NO.localeCompare(b.NO) ) 
        setUsingFemale(usingF);
        setLeftFemale(leftF);
        setUsingMale(usingM);
        setLeftMale(leftM);
         setListFemale(listF);
         setListMale(listM);
      }else{
          
      }
      
    });

    // Cleanup function (컴포넌트 언마운트 시 이벤트 리스너 제거)
    return () => {
      // no specific cleanup needed for onValue
    };
  }, []);
 
   
  const clickEvent = (value)=>{ 
    if(value === "male"){
      setState(1);
    }else if(value === "female"){
      setState(2);
    }else if(value === "back"){
     setState(0);
    //  window.location.reload();
    }
    
  } 
 

   
  if(state === 0)
  return (<MainView   onClickGender = { clickEvent}/>);
   
  if(listMale==null || listFemale==null ){
    return (<div>로딩중...</div>)
  }

  

  return (<div className="container" >  
    <div className='toplococontainer'>
      <img src={TopLogo}   className='toplogo' />
      </div>
      <div className='maintitlecontainer'>
    <img src={state === 1 ?  MaleTitle: FemaleTitle}    className='maintitle2' />
    </div>
    <div className='textlayout'>
       
        <a className='totaltxt'>총 {state ===1 ? listMale.length : listFemale.length }개</a>
        <a className='usingtxt'>사용중 {state ===1 ? usingMale : usingFemale }개</a>
        <a className='lefttxt'>잔여 {state ===1 ?leftMale : leftFemale}개</a>
    </div>
  <div className='lock-group'>
      {
        (state===1 ? listMale : listFemale).map((item)=> (
              <div className= {item.STATE === "1"?'lock-item-free' : 'lock-item-use'} key={item.RFIDV}>{parseInt(item.NO)}</div> 
        ))
      }
    </div>
        <div className='floatingbuttoncontainer'>
        <img src={Home} className='floatingbutton' onClick={()=> clickEvent('back')} />
        </div>
        
    </div>);
 
}

export default App;
